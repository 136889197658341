<template>
  <div class="d-flex flex-column">
    <h1>Mijn profiel - {{ currentUser.name }}</h1>
    <p>
      {{ currentUser.emailAddress }}
    </p>

    <h3 class="pt-5">Wachtwoord wijzigen</h3>

    <validation-observer ref="profileForm" v-slot="{ passes }">
      <b-form v-if="!success" @submit.stop.prevent="passes(onSubmit)">
        <b-form-group label="Wachtwoord" label-for="password" description="Minimaal 8 karakters">
          <ValidationProvider v-slot="v" name="Wachtwoord" rules="required|min:8|confirmed:confirmation">
            <b-form-input id="password" ref="password" v-model="password" name="password" type="password" placeholder="Wachtwoord" :state="getValidationState(v)"></b-form-input>
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="Bevestig wachtwoord" label-for="password_confirmation">
          <ValidationProvider v-slot="v" name="Bevestigd Wachtwoord" vid="confirmation">
            <b-form-input v-model="confirmation" name="password_confirmation" type="password" placeholder="Wachtwoord bevestiging" vid="confirmation" :state="getValidationState(v)"></b-form-input>
            <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <b-button type="submit" variant="primary">Opslaan</b-button>
      </b-form>
    </validation-observer>

    <b-alert v-model="success" variant="success"> Wachtwoord opgeslagen </b-alert>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ValidationMixin from "@/mixins/validation-mixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("UserRepository");

export default {
  components: {},

  mixins: [ValidationMixin],

  data() {
    return {
      password: null,
      confirmation: null,
      success: false
    };
  },

  computed: {
    ...mapState("Users", ["currentUser"])
  },
  methods: {
    onSubmit() {
      this.$refs.profileForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.save();

        this.$nextTick(() => {
          this.$refs.profileForm.reset();
        });
      });
    },

    save: function () {
      UserRepository.passwordChange(this.password)
        .then(() => {
          this.password = this.confirmation = null;
          this.$validator.reset();
          this.success = true;
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
